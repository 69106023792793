'use client'
import React, { useRef, useState } from 'react';
import { IHeaderItemProps } from './HeaderItem.types';
import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import Link from 'next/link';

export const HeaderItem = ({headerItem}: IHeaderItemProps) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        if (headerItem.subRoutes) {
            setAnchorEl(event.currentTarget);
        }
    };
      
    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    return (
        <Box onMouseLeave={handleMouseLeave}>
            <Link href={headerItem.route} passHref>
                <Typography
                    variant='link'
                    color='text.primary'
                    onMouseEnter={handleMouseEnter}
                    sx={{ cursor: 'pointer' }}
                >
                    {headerItem.name}
                </Typography>
            </Link>

            {headerItem.subRoutes && (
                <Menu
                    sx={{mt: 2}}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMouseLeave}
                >
                    {headerItem.subRoutes.map((el, i) => (
                    <MenuItem key={i} onClick={handleMouseLeave}>
                        <Link href={el.route}>
                        <Typography variant='body1' color='text.primary'>
                            {el.name}
                        </Typography>
                        </Link>
                    </MenuItem>
                    ))}
                </Menu>
            )}
        </Box>
    )
}